
  <template>
  <el-row>
    <el-col :span="24" v-if="goodlist.length>0">
        <div class="zone">
            <!-- <div class="imgWraper" @click="jumpDetail(goodlist[0])">
                <img :src="goodlist[0].pic.split(',')[0] | defaultImage"  :alt="goodlist[0].name">
            </div> -->
            <div class="imgWraper" @click="jumpDetail(goodlist[0])">
                <h3 :title="goodlist[0].name">{{goodlist[0].name}}</h3>
                <p :title="goodlist[0].subTitle">{{goodlist[0].subTitle}}</p>
                <span>查看详情</span>
                <img class="imgBox" :src="goodlist[0].pic.split(',')[0]| defaultImage" :alt="goodlist[0].name">
            </div>
            <div class="shopInfo">
                <Shopitem class="item" v-for="(item,index) in goodlist.slice(1,5)" :key="index" :info="item"></Shopitem>


                <div v-if="4 > Number(goodlist.slice(1,5).length)"  class="ItemKong">
                        <img  src="@/assets/AssociatedDrugFirms/Noproduct.png" alt=""/>
                </div>
            </div>
        </div>
    </el-col>
   
  </el-row>
</template>

<style lang="less" scoped>
@import '~style/index.less';

.zone {
    overflow: hidden;
    .imgWraper{
         width: 228px;
         float: left;
        //   height:383px;
         height:450px;
        margin-top: 15px;
        position: relative;
        background: #e5f1ff;
        // background-image: linear-gradient(#efe4db, #d3bbaa);
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
        .imgBox {
            position: absolute;
            left: 14px;
           bottom: 25px;
           width: calc(100% - 28px);
           height: 198px;
            // width: 200px;
            // height: 160px;
        }
        h3{
            position: absolute;
            left: 0;
            top:20px;
            line-height:50px;
            text-align: center;
            width: 100%;
            font-size: 20px;
            font-weight: bold;
            color: #666;
                white-space:nowrap;
text-overflow:ellipsis;
overflow:hidden;
        }
        p{
             position: absolute;
            left: 0;
            top:70px;
            line-height:20px;
            text-align: center;
            font-size:14px;
            color: #999;
            width: 80%;
            margin-left:10%;
            white-space:nowrap;
            text-overflow:ellipsis;
            overflow:hidden;
        }
        span{
              position: absolute;
            left:72px;
            top:100px;
            line-height:20px;
            text-align: center;
            width:70px;
            height:20px;
            line-height:20px;
            font-size:12px;
            color: #999;
            background: #fff;
        }
    }
   .shopInfo {
       float: left;
       width: calc(100% - 228px);
       .item {
           margin-right: 0px!important;
           margin-left: 15px;
       }
       .item:nth-child(4n+0){
            margin-right: 0px;
        }

        .ItemKong{
            width: 228px;
            float: left;
            margin-top: 15px;
            background: #fff;
            margin-right: 0px;
            margin-left: 15px;
            height: 450px;
            position: relative;
            img{
                display: block;
                width:200px;
                height:213px;
                position: absolute;
                top:100px;
                left: 50%;
                margin-left: -100px;
            }
        }
   }
}
   
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
const Shopitem=()=>import('components/index/shopItem.vue');
// 
export default {
    name:"GoodsList",
    data(){
        return {
             defaultImage:defaultImage,
        }
    },
    props:{
        goodlist:{
            type:Array
        }
    },
    created(){
    },
    methods:{
        jumpDetail(goodlist) {
            this.$router.push({ name: "ProductDetail", query: { id: goodlist.id ,isRecentExpiration:goodlist.isRecentExpiration} });
        },    
    },
    components:{
         Shopitem
    }
    
};
</script>
  
